import React from 'react'
import { createRoot } from 'react-dom/client'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import Application from './components/_components/Application'
import rootReducer from './components/_reducers/Index'
import './index.scss'

let store
if (process.env.ENV === 'production') {
  store = createStore(rootReducer)
}
else {
  store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() 
  )
}

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <Application />
      </Provider>
    </div>
  )
}

const container = document.getElementById('sweetescape-fotto')
if (container) {
  const root = createRoot(container)
  root.render(<App />)
}
