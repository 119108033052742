import React, { lazy, Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { isExpiredSession, logout } from 'Helpers/Function';
import Actions from 'Action/Actions';
import PrivateRoute from './PrivateRoute';
import routes from '../_components/routes'

const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const Login = lazy(() => import("../Login/Login"));
const Forgot = lazy(() => import("../Login/Forgot"));
const ChangePassword = lazy(() => import("../Login/ChangePassword"));
const LandingHome = lazy(() => import("../Landing/Home/Home"));
const LandingTermsAndPolicy = lazy(() => import("../Landing/TermsAndPolicy/TermsAndPolicy"));
const LandingContact = lazy(() => import("../Landing/Contact/Contact"));
const LandingThankYou = lazy(() => import("../Landing/ThankYou/ThankYou"));
const LandingExpertises = lazy(() => import("../Landing/Expertises/Expertises"));
const LandingExpertisesDetail = lazy(() => import("../Landing/ExpertisesDetail/ExpertisesDetail"));
const LandingPortfolioDetails = lazy(() => import("../Landing/Portfolios/Show"));
const LandingPortfolios = lazy(() => import("../Landing/Portfolios/Portfolios"));
const LandingPagePublic = lazy(() => import("../LandingPages/Public/Index"));
const NotFound = lazy(() => import("../Global/Error/Error"));

const loading = <div>Loading...</div>;

const Application = () => (
  <Suspense fallback={loading}>
    <Router>
      <AppRoutes />
    </Router>
  </Suspense>
);

const AppRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.AttributesUser);

  useEffect(() => {
    dispatch(Actions.AccessUserData.getData());
  }, [dispatch]);

  useEffect(() => {
    if (user?.data) {
      if (isExpiredSession(user.data.expiry)) {
        logout();
        navigate("/login");
      } else if (/(login|forgot|change-password)$/.test(window.location.pathname)) {
        navigate("/dashboard");
      }
    }
  }, [user?.data, navigate]);

  return (
    <Routes>
      <Route path="/" element={<LandingHome />} />
      <Route path="/:slug" element={<LandingPagePublic />} />
      <Route path="/terms-of-service" element={<LandingTermsAndPolicy />} />
      <Route path="/privacy-policy" element={<LandingTermsAndPolicy />} />
      <Route path="/contact-us" element={<LandingContact />} />
      <Route path="/thank-you" element={<LandingThankYou />} />
      <Route path="/expertises" element={<LandingExpertises />} />
      <Route path="/expertises/:name" element={<LandingExpertisesDetail />} />
      <Route path="/portfolios/:id" element={<LandingPortfolioDetails />} />
      <Route path="/expertises/:title" element={<LandingExpertises />} />
      <Route path="/portfolios" element={<LandingPortfolios />} />

      {/* Public Routes */}
      {!user?.data && (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </>
      )}

      {/* Private Routes */}
      {user?.data && (
        <Route path="/dashboard" element={<PrivateRoute user={user} />}>
          <Route element={<Dashboard />}>
            {routes}
          </Route>
        </Route>
      )}

      <Route path="*" element={<NotFound code={404}/>} />
    </Routes>
  );
};

export default Application;
