import React from 'react'
import styles from './css/Authorized.module.scss'

const Authorized = (props) => {
  let message = props.message !== undefined ? props.message : "You don't have enough permissions."

  return (
    pug`
      .col-12
        div(className=styles.Authorized__Title).Heading_2= message
    `
  )
}

export default Authorized;