import React, { lazy } from "react";
import { Route } from 'react-router-dom';
import Actions from 'Action/Actions';
import { routePermission } from 'Helpers/Function';
import Authorized from 'Authorized';

let permission = false;
//component
const Home = lazy(() => import("../Home/Home"));
//accounts
const AccountProfile = lazy(() => import("../Account/Profile/Detail"));
const EditProfile = lazy(() => import("../Account/Profile/Edit"));
const ChangePassword = lazy(() => import("../Account/Profile/ChangePassword"));
const AccountIndex = lazy(() => import("../Account/Index"));
const AccountNew = lazy(() => import("../Account/Internal/New"));
const AccountNewClient = lazy(() => import("../Account/Client/New"));
const AccountNewCompany = lazy(() => import("../Account/Company/New"));
const AccountNewPartner = lazy(() => import("../Account/Partner/New"));
const AccountDetail = lazy(() => import("../Account/Internal/Detail"));
const AccountDetailClient = lazy(() => import("../Account/Client/Detail"));
const AccountDetailCompany = lazy(() => import("../Account/Company/Detail"));
const AccountDetailPartner = lazy(() => import("../Account/Partner/Detail"));
const AccountEdit = lazy(() => import("../Account/Internal/Edit"));
const AccountEditClient = lazy(() => import("../Account/Client/Edit"));
const AccountEditCompany = lazy(() => import("../Account/Company/Edit"));
const AccountEditPartner = lazy(() => import("../Account/Partner/Edit"));
//companies
const CompanyDetail = lazy(() => import("../Company/Detail"));
const CompanyEdit = lazy(() => import("../Account/Company/Edit"));
const CompanyInvite = lazy(() => import("../Company/Invite"));
//projects
const ProjectIndex = lazy(() => import("../Project/Index"));
const ProjectNew = lazy(() => import("../Project/New"));
const ProjectEdit = lazy(() => import("../Project/Edit"));
const ProjectShow = lazy(() => import("../Project/Show"));
//proposals
const ProposalIndex = lazy(() => import("../Proposals/Index"));
const ProposalNew = lazy(() => import("../Proposals/New"));
const ProposalEdit = lazy(() => import("../Proposals/Edit"));
const ProposalShow = lazy(() => import("../Proposals/Show"));
//shoots
const ShootIndex = lazy(() => import("../Shoots/Index"));
const ShootNew = lazy(() => import("../Shoots/New"));
const ShootEdit = lazy(() => import("../Shoots/Edit"));
const ShootShow = lazy(() => import("../Shoots/Show"));
const RawFilesShow = lazy(() => import("../Shoots/components/RawFilesShow"));
//albums
const AlbumIndex = lazy(() => import("../Albums/Index"));
const AlbumDetail = lazy(()=>import("../Albums/Detail"));
//roles
const RolesIndex = lazy(() => import("../Roles/Index"));
const RolesNew = lazy(() => import("../Roles/Internal/New"));
const RolesNewClient = lazy(() => import("../Roles/Client/New"));
const RolesNewPartner = lazy(() => import("../Roles/Partner/New"));
const RolesDetail = lazy(() => import("../Roles/Internal/Detail"));
const RolesDetailClient = lazy(() => import("../Roles/Client/Detail"));
const RolesDetailPartner = lazy(() => import("../Roles/Partner/Detail"));
const RolesEdit = lazy(() => import("../Roles/Internal/Edit"));
const RolesEditClient = lazy(() => import("../Roles/Client/Edit"));
const RolesEditPartner = lazy(() => import("../Roles/Partner/Edit"));
//partner assignments
const AssignmentIndex = lazy(() => import("../Assignments/Index"));
const AssignmentNew = lazy(() => import("../Assignments/New"));
const AssignmentEdit = lazy(() => import("../Assignments/Edit"));
//portfolios
const PortfolioIndex = lazy(() => import("../Portfolios/Index"));
const PortfolioNew = lazy(() => import("../Portfolios/New"));
const PortfolioEdit = lazy(() => import("../Portfolios/Edit"));
//landing page
const LandingPageIndex = lazy(() => import("../LandingPages/Index"));
const LandingPageNew = lazy(() => import("../LandingPages/New/Index"));
const LandingPageEdit = lazy(() => import("../LandingPages/Edit/Index"));
//expertises
const ExpertiseIndex = lazy(() => import("../Expertises/Index"));
const ExpertisePortfolioIndex = lazy(() => import("../ExpertisePortfolios/Index"));
const ExpertisePortfolioNewIndex = lazy(() => import("../ExpertisePortfolios/New/Index"));
//alias
const ComponentHome = () => {
  return <Home />
}
//account
const ComponentChangePassword = (props) => {
  return <ChangePassword {...props}/>
}
const ComponentAccountProfile = () => {
  return <AccountProfile />
}
const ComponentEditProfile = () => {
  return <EditProfile />
}
const ComponentAccount = () => {
  permission = routePermission('index', 'Users')
  return(
    pug`
      if permission
        AccountIndex
      else
        Authorized
    `
  )
}
const ComponentAccountNew = () => {
  permission = routePermission('create', 'Internal Accounts')
  return(
    pug`
      if permission
        AccountNew
      else
        Authorized
    `
  )
}
const ComponentAccountNewClient = () => {
  permission = routePermission('create', 'Client Accounts')
  return(
    pug`
      if permission
        AccountNewClient
      else
        Authorized
    `
  )
}
const ComponentAccountNewCompany = () => {
  permission = routePermission('create', 'Companies')
  return(
    pug`
      if permission
        AccountNewCompany
      else
        Authorized
    `
  )
}
const ComponentAccountNewPartner = () => {
  permission = routePermission('create', 'Partner Accounts')
  return(
    pug`
      if permission
        AccountNewPartner
      else
        Authorized
    `
  )
}
const ComponentAccountDetail = () => {
  permission = routePermission('show', 'Internal Accounts')
  return(
    pug`
      if permission
        AccountDetail
      else
        Authorized
    `
  )
}
const ComponentAccountDetailClient = () => {
  permission = routePermission('show', 'Client Accounts')
  return(
    pug`
      if permission
        AccountDetailClient
      else
        Authorized
    `
  )
}
const ComponentAccountDetailCompany = () => {
  permission = routePermission('show', 'Companies')
  return(
    pug`
      if permission
        AccountDetailCompany
      else
        Authorized
    `
  )
}
const ComponentAccountDetailPartner = () => {
  permission = routePermission('show', 'Partner Accounts')
  return(
    pug`
      if permission
        AccountDetailPartner
      else
        Authorized
    `
  )
}
const ComponentAccountEdit = () => {
  permission = routePermission('update', 'Internal Accounts')
  return(
    pug`
      if permission
        AccountEdit
      else
        Authorized
    `
  )
}
const ComponentAccountEditClient = () => {
  permission = routePermission('update', 'Client Accounts')
  return(
    pug`
      if permission
        AccountEditClient
      else
        Authorized
    `
  )
}
const ComponentAccountEditCompany = () => {
  permission = routePermission('update', 'Companies')
  return(
    pug`
      if permission
        AccountEditCompany
      else
        Authorized
    `
  )
}
const ComponentAccountEditPartner = () => {
  permission = routePermission('update', 'Partner Accounts')
  return(
    pug`
      if permission
        AccountEditPartner
      else
        Authorized
    `
  )
}
//company
const ComponentCompanyDetail = () => {
  permission = routePermission('list_users', 'Companies')
  return(
    pug`
      if permission
        CompanyDetail
      else
        Authorized
    `
  )
}
const ComponentCompanyEdit = () => {
  permission = routePermission('update', 'Companies')
  return(
    pug`
      if permission
        CompanyEdit
      else
        Authorized
    `
  )
}
const ComponentCompanyInvite = () => {
  permission = routePermission('invite_people', 'Companies')
  return(
    pug`
      if permission
        CompanyInvite
      else
        Authorized
    `
  )
}
//project
const ComponentProject = () => {
  permission = routePermission('index', 'Projects')
  return(
    pug`
      if permission
        ProjectIndex
      else
        Authorized
    `
  )
}
const ComponentProjectNew = () => {
  permission = routePermission('create', 'Projects')
  return(
    pug`
      if permission
        ProjectNew
      else
        Authorized
    `
  )
}
const ComponentProjectEdit = () => {
  permission = routePermission('edit', 'Projects')
  return(
    pug`
      if permission
        ProjectEdit
      else
        Authorized
    `
  )
}
const ComponentProjectShow = () => {
  permission = routePermission('show', 'Projects')
  return(
    pug`
      if permission
        ProjectShow
      else
        Authorized
    `
  )
}
//proposals
const ComponentProposal = () => {
  permission = routePermission('index', 'Proposals')
  return(
    pug`
      if permission
        ProposalIndex
      else
        Authorized
    `
  )
}
const ComponentProposalNew = () => {
  permission = routePermission('create', 'Proposals')
  return(
    pug`
      if permission
        ProposalNew
      else
        Authorized
    `
  )
}
const ComponentProposalEdit = () => {
  permission = routePermission('edit', 'Proposals')
  return (
    pug`
      if permission
        ProposalEdit
      else
        Authorized
    `
  )
}
const ComponentProposalShow = () => {
  permission = routePermission('show', 'Proposals')
  return(
    pug`
      if permission
        ProposalShow
      else
        Authorized
    `
  )
}
//shoots
const ComponentShoot = () => {
  permission = routePermission('index', 'Shoots')
  return(
    pug`
      if permission
        ShootIndex
      else
        Authorized
    `
  )
}
const ComponentShootNew = () => {
  permission = routePermission('create', 'Shoots')
  return(
    pug`
      if permission
        ShootNew
      else
        Authorized
    `
  )
}
const ComponentShootEdit = () => {
  permission = routePermission('update', 'Shoots')
  return(
    pug`
      if permission
        ShootEdit
      else
        Authorized
    `
  )
}
const ComponentShootShow = () => {
  permission = routePermission('show', 'Shoots')
  return(
    pug`
      if permission
        ShootShow
      else
        Authorized
    `
  )
}

//albums
const ComponentAlbum = () => {
  permission = routePermission('index', 'Albums')
  return(
    pug`
      if permission
        AlbumIndex
      else
        Authorized
    `
  )
}

const ComponentAlbumDetail = () => {
  permission = routePermission('show', 'Albums')
  return(
    pug`
      if permission
        AlbumDetail
      else
        Authorized
    `
  )
}

//rawFiles
const ComponentRawFilesShow = () => {
  permission = routePermission('show', 'Raw Media Batches')

  return (
    pug`
      if permission
        RawFilesShow
      else
        Authorized
    `
  )
}

//roles
const ComponentRoles = () => {
  permission = routePermission('index', 'Roles')
  return(
    pug`
      if permission
        RolesIndex
      else
        Authorized
    `
  )
}
const ComponentRolesNew = () => {
  permission = routePermission('create', 'Roles')
  return(
    pug`
      if permission
        RolesNew
      else
        Authorized
    `
  )
}
const ComponentRolesNewClient = () => {
  permission = routePermission('create', 'Roles')
  return(
    pug`
      if permission
        RolesNewClient
      else
        Authorized
    `
  )
}
const ComponentRolesNewPartner = () => {
  permission = routePermission('create', 'Roles')
  return(
    pug`
      if permission
        RolesNewPartner
      else
        Authorized
    `
  )
}
const ComponentRolesDetail = () => {
  permission = routePermission('show', 'Roles')
  return(
    pug`
      if permission
        RolesDetail
      else
        Authorized
    `
  )
}
const ComponentRolesDetailClient = () => {
  permission = routePermission('show', 'Roles')
  return(
    pug`
      if permission
        RolesDetailClient
      else
        Authorized
    `
  )
}
const ComponentRolesDetailPartner = () => {
  permission = routePermission('show', 'Roles')
  return(
    pug`
      if permission
        RolesDetailPartner
      else
        Authorized
    `
  )
}
const ComponentRolesEdit = () => {
  permission = routePermission('update', 'Roles')
  return(
    pug`
      if permission
        RolesEdit
      else
        Authorized
    `
  )
}
const ComponentRolesEditClient = () => {
  permission = routePermission('update', 'Roles')
  return(
    pug`
      if permission
        RolesEditClient
      else
        Authorized
    `
  )
}
const ComponentRolesEditPartner = () => {
  permission = routePermission('update', 'Roles')
  return(
    pug`
      if permission
        RolesEditPartner
      else
        Authorized
    `
  )
}
//assignment
const ComponentAssignmentIndex = () => {
  permission = routePermission('pending_assignments', 'Shoots')
  return (
    pug`
      if permission
        AssignmentIndex
      else
        Authorized
    `
  )
}
const ComponentAssignmentNew = () => {
  permission = routePermission('create', 'Partner Assignments')
  return (
    pug`
      if permission
        AssignmentNew
      else
        Authorized
    `
  )
}
const ComponentAssignmentEdit = () => {
  permission = routePermission('update', 'Partner Assignments')
  return (
    pug`
      if permission
        AssignmentEdit
      else
        Authorized
    `
  )
}

//portfolio
const ComponentPortfolios = () => {
  permission = routePermission('index', 'Portfolios')
  return(
    pug`
      if permission
        PortfolioIndex
      else
        Authorized
    `
  )
}
  
const ComponentPortfolioNew = () => {
  permission = routePermission('create', 'Portfolios')
  return (
    pug`
      if permission
        PortfolioNew
      else
        Authorized
    `
  )
}

const ComponentPortfolioEdit = () => {
  permission = routePermission('update', 'Portfolios')
  return (
    pug`
      if permission
        PortfolioEdit
      else
        Authorized
    `
  )
}

// landing page
const ComponentLandingPages = () => {
  permission = true
  return (
    pug`
      if permission
        LandingPageIndex
      else
        Authorized
    `
  )
}
const ComponentLandingPageNew = () => {
  permission = true
  return (
    pug`
      if permission
        LandingPageNew
      else
        Authorized
    `
  )
}
const ComponentLandingPageEdit = () => {
  permission = true
  return (
    pug`
      if permission
        LandingPageEdit
      else
        Authorized
    `
  )
}

// expertises
const ComponentExpertises = () => {
  permission = routePermission('update_expertise_portfolio', 'Expertises') || routePermission('upload_expertise_portfolio', 'Expertises') || routePermission('remove_expertise_portfolio', 'Expertises')
  return (
    pug`
      if permission
        ExpertiseIndex
      else
        Authorized
    `
  )
}
const ComponentExpertisePortfolios = () => {
  permission = routePermission('update_expertise_portfolio', 'Expertises') || routePermission('upload_expertise_portfolio', 'Expertises') || routePermission('remove_expertise_portfolio', 'Expertises')
  return (
    pug`
      if permission
        ExpertisePortfolioIndex
      else
        Authorized
    `
  )
}
const ComponentExpertisePortfolioNew = () => {
  permission = routePermission('update_expertise_portfolio', 'Expertises') || routePermission('upload_expertise_portfolio', 'Expertises') || routePermission('remove_expertise_portfolio', 'Expertises')
  return (
    pug`
      if permission
        ExpertisePortfolioNewIndex
      else
        Authorized
    `
  )
}

const routes = (
  <>
    <Route index element={<ComponentHome />} />
    <Route path="/dashboard/home" element={<ComponentHome />} />
    <Route path="/dashboard/profile" element={<ComponentAccountProfile />} />
    <Route path="/dashboard/edit/profile" element={<ComponentEditProfile />} />
    <Route path="/dashboard/profile/password" element={<ComponentChangePassword />} />
    
    {/* Accounts */}
    <Route path="/dashboard/accounts" element={<ComponentAccount />} />
    <Route path="/dashboard/accounts/new" element={<ComponentAccountNew />} />
    <Route path="/dashboard/accounts/new/client" element={<ComponentAccountNewClient />} />
    <Route path="/dashboard/accounts/new/company" element={<ComponentAccountNewCompany />} />
    <Route path="/dashboard/accounts/new/partner" element={<ComponentAccountNewPartner />} />
    <Route path="/dashboard/accounts/detail/:id" element={<ComponentAccountDetail />} />
    <Route path="/dashboard/accounts/detail/client/:id" element={<ComponentAccountDetailClient />} />
    <Route path="/dashboard/accounts/detail/company/:id" element={<ComponentAccountDetailCompany />} />
    <Route path="/dashboard/accounts/detail/partner/:id" element={<ComponentAccountDetailPartner />} />
    <Route path="/dashboard/accounts/edit/:id" element={<ComponentAccountEdit />} />
    <Route path="/dashboard/accounts/edit/client/:id" element={<ComponentAccountEditClient />} />
    <Route path="/dashboard/accounts/edit/company/:id" element={<ComponentAccountEditCompany />} />
    <Route path="/dashboard/accounts/edit/partner/:id" element={<ComponentAccountEditPartner />} />

    {/* Companies */}
    <Route path="/dashboard/companies/:id" element={<ComponentCompanyDetail />} />
    <Route path="/dashboard/companies/edit/:id" element={<ComponentCompanyEdit />} />
    <Route path="/dashboard/companies/invite/:id" element={<ComponentCompanyInvite />} />

    {/* Projects */}
    <Route path="/dashboard/projects" element={<ComponentProject />} />
    <Route path="/dashboard/projects/new" element={<ComponentProjectNew />} />
    <Route path="/dashboard/projects/edit/:code" element={<ComponentProjectEdit />} />
    <Route path="/dashboard/projects/show/:code" element={<ComponentProjectShow />} />

    {/* Proposals */}
    <Route path="/dashboard/proposals" element={<ComponentProposal />} />
    <Route path="/dashboard/proposals/new" element={<ComponentProposalNew />} />
    <Route path="/dashboard/proposals/edit/:code" element={<ComponentProposalEdit />} />
    <Route path="/dashboard/proposals/show/:code" element={<ComponentProposalShow />} />

    {/* Shoots */}
    <Route path="/dashboard/project/:projectCode/shoots" element={<ComponentShoot />} />
    <Route path="/dashboard/project/:projectCode/shoot/:shootCode" element={<ComponentShootShow />} />
    <Route path="/dashboard/shoots" element={<ComponentShoot />} />
    <Route path="/dashboard/shoots/new" element={<ComponentShootNew />} />
    <Route path="/dashboard/shoots/edit/:code" element={<ComponentShootEdit />} />
    <Route path="/dashboard/shoots/:shootCode/albums" element={<ComponentAlbum />} />
    <Route path="/dashboard/shoots/:shootCode/album/:id" element={<ComponentAlbumDetail />} />
    <Route path="/dashboard/shoots/:shootCode/raws/:id" element={<ComponentRawFilesShow />} />

    {/* Assignments */}
    <Route path="/dashboard/requirement/:requirementId/assignment/new" element={<ComponentAssignmentNew />} />
    <Route path="/dashboard/requirement/:requirementId/assignment/:assignmentId/edit" element={<ComponentAssignmentEdit />} />

    {/* Roles */}
    <Route path="/dashboard/roles" element={<ComponentRoles />} />
    <Route path="/dashboard/roles/new" element={<ComponentRolesNew />} />
    <Route path="/dashboard/roles/new/client" element={<ComponentRolesNewClient />} />
    <Route path="/dashboard/roles/new/partner" element={<ComponentRolesNewPartner />} />
    <Route path="/dashboard/roles/detail/:id" element={<ComponentRolesDetail />} />
    <Route path="/dashboard/roles/detail/client/:id" element={<ComponentRolesDetailClient />} />
    <Route path="/dashboard/roles/detail/partner/:id" element={<ComponentRolesDetailPartner />} />
    <Route path="/dashboard/roles/edit/:id" element={<ComponentRolesEdit />} />
    <Route path="/dashboard/roles/edit/client/:id" element={<ComponentRolesEditClient />} />
    <Route path="/dashboard/roles/edit/partner/:id" element={<ComponentRolesEditPartner />} />

    {/* Partners */}
    <Route path="/dashboard/partners/assignment" element={<ComponentAssignmentIndex />} />

    {/* Portfolios */}
    <Route path="/dashboard/portfolios" element={<ComponentPortfolios />} />
    <Route path="/dashboard/portfolios/new" element={<ComponentPortfolioNew />} />
    <Route path="/dashboard/portfolios/edit/:id" element={<ComponentPortfolioEdit />} />

    {/* Landing Pages */}
    <Route path="/dashboard/landingpages" element={<ComponentLandingPages />} />
    <Route path="/dashboard/landingpages/new" element={<ComponentLandingPageNew />} />
    <Route path="/dashboard/landingpages/edit/:id" element={<ComponentLandingPageEdit />} />

    {/* Expertises */}
    <Route path="/dashboard/expertises" element={<ComponentExpertises />} />
    <Route path="/dashboard/expertiseportfolios" element={<ComponentExpertisePortfolios />} />
    <Route path="/dashboard/expertiseportfolios/new" element={<ComponentExpertisePortfolioNew />} />
  </>
)

export default routes;
